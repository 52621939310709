<template>
  <div class="home">
    <Swiper></Swiper>
    <!-- <SubNav></SubNav>
    <div class="news">
      <MiddleBox></MiddleBox>
      <div class="right">
        <long-box></long-box>
      </div>
    </div> -->
    <div class="list">
      富文本
    </div>
    <div class="message">
      <message></message>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import LongBox from "./modules/longBoxa.vue";
import MiddleBox from "./modules/middleBox.vue";
import Message from "@/components/message/Message.vue";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    LongBox,
    MiddleBox,
    Message,
  },
  data() {
    return {
    };
  },
});
</script>
<style lang="scss" scoped>
// .news {
//   width: 1200px;
//   height: 100%;
//   margin-bottom: 20px;
//   margin-top: 20px;
//   position: relative;
//   .right {
//     position: absolute;
//     right: 0;
//     top: 0;
//   }
// }
.list{
  width: 1200px;
  min-height: 300px;
  background-color: #fff;
  padding-top: 56px;
}
.message {
  width: 1200px;
  // background-color: skyblue;
  height: 100%;
  margin-bottom: 20px;
  margin-top: 56px;
}
</style>
