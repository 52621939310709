<template>
  <div>
    <div class="box">
      <div class="nav">专家背景</div>
      <div class="boxMain">
        <div class="item" v-for="item in list" :key="item">
                  <img class="img" :src="item.img" alt="">
                  <div class="right"> 
                      <p class="title">{{item.title}}</p>
                      <p class="content">{{item.content}}</p>
                  </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "middleBox",
  data() {
    return {
      list: [
        {
          img: require("@/assets/首页/生涯云平台.png"),
          title: "專家專家",
          id:1,
          content:
            "專家專家",
        },
      ],
    };
  },
  methods:{
    change(val:any){
       console.log(`当前页: ${val}`);
    }
  }
});
</script>
<style lang='scss' scoped>
@import "~@/assets/styles/mixin.scss";
.pagination {
  width: 500px;
  display: block;
  margin: 40px auto;
}
.box {
  width: 916px;
  max-height: 780px;
  height: 100%;
  min-height: 200px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  .nav{
    border-left: 9px solid #adff2f;
    padding-left: 26px;
    height: 30px;
    font-size: 22px;
    line-height: 30px;
  }
  
  .boxMain {
    height: 100%;
    padding: 20px 0;
    // max-height: 760px;
    .img{
    width: 170px;
    height: 130px;
float: left;  }
    .item{
      margin-top: 20px;
    }
    .right{
      float: left;
      width: 600px;
      height: 130px;
      margin-left: 25px;
      .title{
        margin-top: 25px;
        font-size: 24px;
      }
      .content{
        margin-top: 15px;
        font-size: 14px;
      }
    }
  }
}
</style>
