
import { defineComponent } from "vue";
export default defineComponent({
  name: "middleBox",
  data() {
    return {
      list: [
        {
          img: require("@/assets/首页/生涯云平台.png"),
          title: "專家專家",
          id:1,
          content:
            "專家專家",
        },
      ],
    };
  },
  methods:{
    change(val:any){
       console.log(`当前页: ${val}`);
    }
  }
});
