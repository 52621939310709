
import { defineComponent } from "vue";
export default defineComponent({
  name: "swiper",
  data() {
    return {
      imgList: [
        require("@/assets/首页/轮播/4-2.png"),
        require("@/assets/首页/轮播/1-1.png"),
        require("@/assets/首页/轮播/图片2.png"),
        require("@/assets/首页/轮播/图片1.png"),
      ],
    };
  },
});
