<template>
  <div class="home">
    <div class="swiper">
      <el-carousel>
        <el-carousel-item v-for="item,  in imgList" :key="item">
          <img class="img" :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "swiper",
  data() {
    return {
      imgList: [
        require("@/assets/首页/轮播/4-2.png"),
        require("@/assets/首页/轮播/1-1.png"),
        require("@/assets/首页/轮播/图片2.png"),
        require("@/assets/首页/轮播/图片1.png"),
      ],
    };
  },
});
</script>
<style lang='scss'>
.swiper {
  width: 1920px;
  margin-left: -360px;
  .img {
    width: 1920px;
    height: 310px;
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
